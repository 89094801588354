import React from 'react';
import './../style.css';
import icono from './../../resources/icono.png'
import toggle from './../../resources/toggle.png'
import {Nav, Navbar, Container} from 'react-bootstrap';

const EnHeader = () => { 
return ( 
  <Navbar expand="lg">
    <Container>
      <Navbar.Brand href="/en">
        <img src={icono} alt='Ruta' className='logo'/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{borderColor: 'transparent'}}>
        <img src={toggle} alt='Norte' className='toggle'/>
      </Navbar.Toggle>
      <Navbar.Collapse>
        <Nav.Link href="/en/lodging">
          <p className='link'>Lodging</p>
        </Nav.Link>
        <Nav.Link href="/en/restaurants">
          <p className='link'>Restaurants</p>
        </Nav.Link>
        <Nav.Link href="/en/activities">
          <p className='link'>Activities</p>
        </Nav.Link>
        <Nav.Link href="/en/volunteering">
          <p className='link'>Volunteering</p>
        </Nav.Link>
      </Navbar.Collapse>
    </Container>
  </Navbar>
); 
}; 

export default EnHeader;

import React from "react";
import logo from "./../../resources/logoNegro.png";
import icono from './../../resources/logoBlanco.png'
import toggle from "./../../resources/toggleBlanco.png";
import landingPageBackgroundVideo from "./../../resources/landingPageBackgroundVideo.webm";
import landingPageBackgroundImage from "./../../resources/landingPageBackgroundImage.JPG";
import triangulo from "./../../resources/triangulo.svg";
import { Container, Nav, Navbar } from "react-bootstrap";
import EnAboutUs from "./EnAboutUs";
import EnContactUs from "./EnContactUs";
import LanguageButton from "./../LanguageButton";
import Footer from "../Footer";

const EnMainPage = () => {
  return (
    <main>
      <section className="backgroundVideo">
        <video autoPlay loop muted poster={landingPageBackgroundImage}>
          <source src={landingPageBackgroundVideo} type="video/webm" />
        </video>
        <Navbar
          expand="lg"
          style={{
            backgroundColor: 'transparent',
            position: "fixed",
            width: "100%",
          }}
        >
          <Container>
            <Navbar.Brand href="/en">
              <img src={icono} alt='Ruta' className='logo'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{borderColor: 'transparent'}}>
              <img src={toggle} alt='Norte' className='toggle'/>
            </Navbar.Toggle>
            <Navbar.Collapse>
              <Nav.Link href="/en/lodging">
                <p className='linkInicio'>Lodging</p>
              </Nav.Link>
              <Nav.Link href="/en/restaurants">
                <p className='linkInicio'>Restaurants</p>
              </Nav.Link>
              <Nav.Link href="/en/activities">
                <p className='linkInicio'>Activities</p>
              </Nav.Link>
              <Nav.Link href="/en/volunteering">
                <p className='linkInicio'>Volunteering</p>
              </Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <img src={logo} alt='Logo' className="logoInicio"/>
        <div className="buttonContainer">
          <div/>
          <a href="#scrollReference" className="buttonInicio">
            About us
          </a>
        </div>
      </section>
      <img src={triangulo} alt='Triangulo' className="trianguloInferior"/>
      <section className="scrollOver">
        <EnAboutUs />
        <EnContactUs />
        <Footer />
      </section>
      <LanguageButton pagina={'/es'} lenguaje={'Spanish'}/>
    </main>
  );
};

export default EnMainPage;

import React from "react";
import { Container, Row } from "react-bootstrap";
import Emprendimientos from '../../resources/emprendimientos.json'
import EnHeader from "./EnHeader";
import BusinessCard from "../Es/EsBusinessCard";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const emprendimientos = Emprendimientos.hospedaje;
const EnLodging = () => {
  return (
    <article>
      <EnHeader />
      <Container>
      <br/><br/>
        <Row>
          <h1 className="title">Lodging</h1>
        </Row>
        <Row className="emprendimientos">
          {emprendimientos.map((emprendimiento) => <BusinessCard titulo={emprendimiento.titulo} identificador={emprendimiento.id} key={emprendimiento.id}/>)}
        </Row>
      </Container>
      <Footer />
      <LanguageButton pagina={'/es/lodging'} lenguaje={'Spanish'}/>
    </article>
  );
};

export default EnLodging;

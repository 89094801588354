import React from 'react';
import {Container, Row} from 'react-bootstrap';
import EsHeader from './EsHeader';
import Footer from '../Footer';
import LanguageButton from '../LanguageButton';

const EsError = () => { 
  return (
    <article>
      <EsHeader/>
      <br/><br/>
      <Container>
        <Row>
          <h1 className="title">Error: 404</h1>
        </Row>
        <Row>
          <h3 className="subtitle">No hemos sido capaces de encontrar la página que usted está buscando.</h3>
        </Row>
        <div className='buttonContainer'><a href='/es' className='button'>Volver al inicio</a></div>
        <div style={{height: '35vh'}}/>
      </Container>
      <Footer/>
      <LanguageButton pagina={'/en/error'} lenguaje={'English'}/>
    </article>
  ); 
}; 

export default EsError;
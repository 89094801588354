import React from "react";
import colibri from "./../../resources/colibri.png";
import brujula from "./../../resources/brujula.png";
import maleta from "./../../resources/maleta.png";
import cama from "./../../resources/cama.png";
import plato from "./../../resources/plato.png";
import caminante from "./../../resources/caminante.png";
import { Container, Row, Col} from "react-bootstrap";

export const EnAboutUs = () => {
  return (
    <Container>
    <h1 className="title" id="scrollReference" style={{paddingTop: '10vh', paddingBottom: '10vh'}}>About us</h1>
    <Row>
      <Col lg={4}>
        <article>
          <img src={colibri} alt="colibri" className="cartaInicio"/>
          <h2 className="cartaInicio">Flora & Fauna</h2>
          <p className="cartaInicio">
            Come and visit our national parks full of
            nature.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={brujula} alt="brujula" className="cartaInicio" />
          <h2 className="cartaInicio">Adventure</h2>
          <p className="cartaInicio">
            The unknown Costa Rica awaits you. Come and 
            discover it with us.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={maleta} alt="maleta" className="cartaInicio" />
          <h2 className="cartaInicio">Volunteering</h2>
          <p className="cartaInicio">
            Fill out our online form and apply for our opportunities.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={cama} alt="cama" className="cartaInicio" />
          <h2 className="cartaInicio">Lodging</h2>
          <p className="cartaInicio">
            Discover our budget friendly options.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={plato} alt="plato" className="cartaInicio" />
          <h2 className="cartaInicio">Food</h2>
          <p className="cartaInicio">
            Taste our native cuisine.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={caminante} alt="caminante" className="cartaInicio" />
          <h2 className="cartaInicio">Activities</h2>
          <p className="cartaInicio">
            Our tour guides are at your disposal.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">Our team</h2>
          <p className="cartaInicio">
          Ruta Norte is made up of independent entrepreneurs who are experts in the area of tourism, 
          whether in accommodation, food or tourist activities.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">What is Ruta Norte?</h2>
          <p className="cartaInicio">
            We are a tourism initiative created by seven tourism chambers, 
            that today make up the Ruta Norte Federation.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">The origins of our project</h2>
          <p className="cartaInicio">
            During the last months of the pandemic, an economic alternative was devised 
            to help ecotourism entrepreneurs in the border regions of the 
            northern Costa Rica.
          </p>
        </article>
      </Col>
    </Row>
    </Container>
  );
};

export default EnAboutUs;

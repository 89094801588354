import React from "react";
import { Container, Row } from "react-bootstrap";
import Emprendimientos from '../../resources/emprendimientos.json'
import EsHeader from "./EsHeader";
import BusinessCard from "./EsBusinessCard";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const emprendimientos = Emprendimientos.hospedaje;
const EsLodging = () => {
  return (
    <article>
      <EsHeader />
      <Container>
      <br/><br/>
        <Row>
          <h3 className="subtitle">Nuestras opciones de</h3>
        </Row>
        <Row>
          <h1 className="title">Hospedaje</h1>
        </Row>
        <Row className="emprendimientos">
          {emprendimientos.map((emprendimiento) => <BusinessCard titulo={emprendimiento.titulo} identificador={emprendimiento.id} key={emprendimiento.id}/>)}
        </Row>
      </Container>
      <Footer />
      <LanguageButton pagina={'/en/lodging'} lenguaje={'English'}/>
    </article>
  );
};

export default EsLodging;

import React from "react";
import { Routes, Route} from 'react-router-dom';
import EsMainPage from "./components/Es/EsMainPage";
import EsLodging from "./components/Es/EsLodging";
import EsRestaurants from "./components/Es/EsRestaurants";
import EsActivities from "./components/Es/EsActivities";
import EsVolunteering from "./components/Es/EsVolunteering";
import EsForm from "./components/Es/EsForm";
import EsCompletedForm from "./components/Es/EsCompletedForm";
import EsError from "./components/Es/EsError";
import EsBusiness from "./components/Es/EsBusiness";
import EnMainPage from "./components/En/EnMainPage";
import EnLodging from "./components/En/EnLodging";
import EnRestaurants from "./components/En/EnRestaurants";
import EnActivities from "./components/En/EnActivities";
import EnVolunteering from "./components/En/EnVolunteering";
import EnForm from "./components/En/EnForm";
import EnCompletedForm from "./components/En/EnCompletedForm";
import EnError from "./components/En/EnError";
import EnBusiness from "./components/En/EnBusiness";

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element ={<EsMainPage/>}></Route>
        <Route path='*' element ={<EsError/>}></Route>
        <Route path='/es' element ={<EsMainPage/>}></Route>
        <Route path='/es/lodging' element ={<EsLodging/>}></Route>
        <Route path='/es/restaurants' element ={<EsRestaurants/>}></Route>
        <Route path='/es/activities' element ={<EsActivities/>}></Route>
        <Route path='/es/volunteering' element ={<EsVolunteering/>}></Route>
        <Route path='/es/form' element ={<EsForm/>}></Route>
        <Route path='/es/completedForm' element ={<EsCompletedForm/>}></Route>
        <Route path='/es/business/:identificadorDelEmprendimiento' element ={<EsBusiness/>}></Route>
        <Route path='/en' element ={<EnMainPage/>}></Route>
        <Route path='/en/lodging' element ={<EnLodging/>}></Route>
        <Route path='/en/restaurants' element ={<EnRestaurants/>}></Route>
        <Route path='/en/activities' element ={<EnActivities/>}></Route>
        <Route path='/en/volunteering' element ={<EnVolunteering/>}></Route>
        <Route path='/en/form' element ={<EnForm/>}></Route>
        <Route path='/en/completedForm' element ={<EnCompletedForm/>}></Route>
        <Route path='/en/business/:identificadorDelEmprendimiento' element ={<EnBusiness/>}></Route>
        <Route path='/en/*' element ={<EnError/>}></Route>
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import colibri from "./../../resources/colibri.png";
import brujula from "./../../resources/brujula.png";
import maleta from "./../../resources/maleta.png";
import cama from "./../../resources/cama.png";
import plato from "./../../resources/plato.png";
import caminante from "./../../resources/caminante.png";
import { Container, Row, Col} from "react-bootstrap";

export const EsAboutUs = () => {
  return (
    <Container>
    <h1 className="title" id="scrollReference" style={{paddingTop: '10vh', paddingBottom: '10vh'}}>Sobre Nosotros</h1>
    <Row>
      <Col lg={4}>
        <article>
          <img src={colibri} alt="colibri" className="cartaInicio"/>
          <h2 className="cartaInicio">Flora & Fauna</h2>
          <p className="cartaInicio">
            Ven a visitar nuestros parques nacionales llenos de
            naturaleza.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={brujula} alt="brujula" className="cartaInicio" />
          <h2 className="cartaInicio">Aventura</h2>
          <p className="cartaInicio">
            Te espera la Costa Rica desconocida. Ven a descubrirla con
            nosotros.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={maleta} alt="maleta" className="cartaInicio" />
          <h2 className="cartaInicio">Voluntariado</h2>
          <p className="cartaInicio">
            Completa nuestro formulario y aplica a nuestras oportunidades.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={cama} alt="cama" className="cartaInicio" />
          <h2 className="cartaInicio">Hospedaje</h2>
          <p className="cartaInicio">
            Descubra nuestra variedad flexible a su presupuesto.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={plato} alt="plato" className="cartaInicio" />
          <h2 className="cartaInicio">Alimentación</h2>
          <p className="cartaInicio">
            Deguste de nuestra gastronomía autóctona.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <img src={caminante} alt="caminante" className="cartaInicio" />
          <h2 className="cartaInicio">Actividades</h2>
          <p className="cartaInicio">
            Nuestros guías turísticos están a su disposición.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">¿Quiénes somos?</h2>
          <p className="cartaInicio">
          Nuestro equipo está conformado por emprendedores independientes expertos en el área de turismo, 
          ya sea en el hospedaje, en la alimentación o en actividades turísticas.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">¿Qué somos?</h2>
          <p className="cartaInicio">
            Ruta Norte es una iniciativa turística creada por siete cámaras de turismo, 
            que hoy conforman la Federacion Ruta Norte.
          </p>
        </article>
      </Col>
      <Col lg={4}>
        <article>
          <h2 className="cartaInicio">¿Por qué nace este proyecto?</h2>
          <p className="cartaInicio">
          Durante  los últimos meses de la pandemia se ideó una posible alternativa económica 
          para ayudar a los empresarios del turismo rural y comunitario de los cantones fronterizos del 
          norte de Costa Rica. Así nació Ruta Norte!
          </p>
        </article>
      </Col>
    </Row>
    </Container>
  );
};

export default EsAboutUs;

import React from "react";
import { Container, Row } from "react-bootstrap";
import Emprendimientos from '../../resources/emprendimientos.json'
import EnHeader from "./EnHeader";
import EnBusinessCard from "../En/EnBusinessCard";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const emprendimientos = Emprendimientos.actividades;
const EnActivities = () => {
  return (
    <article>
      <EnHeader />
      <br/><br/>
      <Container>
        <Row>
          <h1 className="title">Activities</h1>
        </Row>
        <Row>
          {emprendimientos.map((emprendimiento) => <EnBusinessCard titulo={emprendimiento.titulo} identificador={emprendimiento.id} key={emprendimiento.id}/>)}
        </Row>
      </Container>
      <Footer />
      <LanguageButton pagina={'/es/activities'} lenguaje={'Spanish'}/>
    </article>
  );
};

export default EnActivities;

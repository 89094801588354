import React from 'react';
import {Container, Row} from 'react-bootstrap';
import EnHeader from './EnHeader';
import Footer from '../Footer';
import LanguageButton from "../LanguageButton";

const EnError = () => { 
  return (
    <article>
      <EnHeader/>
      <br/><br/>
      <Container>
        <Row>
          <h1 className="title">Error: 404</h1>
        </Row>
        <Row>
          <h3 className="subtitle">We have not found the page you are looking for.</h3>
        </Row>
        <div className='buttonContainer'><a href='/en' className='button'>Main page</a></div>
        <div style={{height: '35vh'}}/>
      </Container>
      <Footer/>
      <LanguageButton pagina={'/es/error'} lenguaje={'Spanish'}/>
    </article>
  ); 
}; 

export default EnError;
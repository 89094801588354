import React, { useRef, useState } from 'react';
import {useForm} from 'react-hook-form';
import {Container, Row, Col} from "react-bootstrap";
import emailjs from '@emailjs/browser';
const EsContactUs = () => {
  const form = useRef();
  const {register, formState: {errors}, handleSubmit} = useForm();
  const [formSent, setformSent] = useState(false);
  const sendEmail = (e) => {
    setformSent(true);
    emailjs
    .sendForm('service_9nxgimi', 'template_ck7sh1r', form.current, {
      publicKey: 'J3w64gwrVVOxIbSZJ',
    })
  };
  return (
    <Container className="formularioHoja">
      <Row className='centrado'>
        <Col lg={8}>
          {!formSent &&
          <form ref={form} onSubmit={handleSubmit(sendEmail)}>
            <h1 className='title'>Contáctenos</h1>
            <label>Nombre completo</label>
            <input type="text" name='nombre' className="form"  {...register('nombre' ,{
              maxLength: 128,
              pattern: (/^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]*$/),
              required: true
            })}/>
            {errors.nombre?.type === 'maxLength' && <p className="error">Ingrese menos de 128 caracteres</p>}
            {errors.nombre?.type === 'pattern' && <p className="error">Ingrese un nombre real</p>}
            {errors.nombre?.type === 'required' && <p className="error">Ingrese un nombre</p>}
            <label>Correo electrónico</label>
            <input type="text" name='correo' className="form" {...register('correo' ,{
              maxLength: 512,
              pattern: (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
              required: true
            })}/>
            {errors.correo?.type === 'maxLength' && <p className="error">Ingrese menos de 128 caracteres</p>}
            {errors.correo?.type === 'pattern' && <p className="error">Ingrese un correo real</p>}
            {errors.correo?.type === 'required' && <p className="error">Ingrese un correo</p>}
            <label>Escriba su mensaje</label>
            <textarea name='motivo' className="formularioRespuestaLarga" {...register('motivo' ,{
              maxLength: 300,
              pattern: (/^[a-zA-Z\s.,?!]*$/)
            })}/>
            {errors.motivo?.type === 'maxLength' && <p className="error">Ingrese menos de 300 caracteres</p>}
            {errors.motivo?.type === 'pattern' && <p className="error">Utilize unicamente letras, números, puntos y comas.</p>}
            <div className="buttonContainer">
              <input type="submit" value="Enviar" className="button" />
            </div>
          </form>
          }
          {formSent &&
            <div>
              <h1 className='title'>Mensaje enviado</h1>
              <h3 className='subtitle'>Gracias por contactar a la Ruta Norte, la Costa Rica desconocida.</h3>
            </div>
          }
        </Col>
      </Row>
    </Container>
  );
};

export default EsContactUs;

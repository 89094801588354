import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import tapaDulce from "./../../resources/tapadulce.png";
import "./../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import EnHeader from "./EnHeader";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const EnVolunteering = () => {
  return (
    <article>
      <EnHeader />
      <br/><br/>
      <Container>
        <Row>
          <h1 className="title">Volunteering</h1>
        </Row>
        <Row>
          <Col lg={6}>
            <img src={tapaDulce} alt="Tapa Dulce" className="voluntariadoImage" />
          </Col>
          <Col lg={6}>
            <p className="voluntariadoText">
              By completing our form, you will take the first step to connect with an entrepreneur from Ruta Norte,
              which will give you the chance to explore the unknown Costa Rica as a volunteer.
              This will give you the unique opportunity to gain valuable skills and experience while contributing to a project.
              meaningful that aligns with your interests and values.
            </p>
            <p className="voluntariadoTitle">
              Come and visit the unknown Costa Rica!
            </p>
          </Col>
        </Row>
        <div className="buttonContainer">
          <a href="/en/form" className="button">
            Form
          </a>
        </div>
      </Container>
      <Footer />
      <LanguageButton pagina={'/es/volunteering'} lenguaje={'Spanish'}/>
    </article>
  );
};

export default EnVolunteering;

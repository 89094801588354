import React from 'react';
import {Container} from 'react-bootstrap';

const EsCompletedForm = () => { 
  return (
    <article>
      <Container className='formularioHoja'>
        <form>
        <h1 className="title">Formulario completado</h1>
        <p className='parrafo'>
            Gracias por rellenar nuestro formulario de voluntariado. 
            Tu solicitud será enviada a los emprendedores de la Ruta Norte, 
            a partir de ahora estás aplicando a nuestras oportunidades voluntariado.
        </p>
        <div className='buttonContainer'><a href='/es' className='button'>Volver</a></div>
        </form>
      </Container>
      <div className='separador'></div>
    </article>
  ); 
}; 

export default EsCompletedForm;
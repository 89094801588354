import React from "react";
import "./style.css";
import lenguaje from "../resources/lenguaje.png"

export default class LanguageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: props.pagina,
      lenguaje: props.lenguaje
    }
  }
  render() {
    return (
      <div className="languageButtonContainer">
        <a href={this.state.pagina} className="button">
          <img src={lenguaje} alt='Lenguaje' className='footerIcon'/>
          &nbsp; {this.props.lenguaje}
        </a>
      </div>
    );
  }
};

import React, { useRef, useState } from 'react';
import {useForm} from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { Container } from "react-bootstrap";
import "./../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import EnHeader from "./EnHeader";
import Footer from "../Footer";
import EnCompletedForm from './EnCompletedForm';
import LanguageButton from "../LanguageButton";

const EnFormulario = () => {
  const form = useRef();
  const {register, formState: {errors}, handleSubmit} = useForm();
  const [formSent, setformSent] = useState(false);
  const sendEmail = (e) => {
    setformSent(true);
    emailjs
    .sendForm('service_9nxgimi', 'template_nox4d1s', form.current, {
      publicKey: 'J3w64gwrVVOxIbSZJ',
    })
  };
  return (
    <article>
      <EnHeader />
      {!formSent &&
        <Container className="formularioHoja">
          <form ref={form} onSubmit={handleSubmit(sendEmail)}>
            <label>Full name</label>
            <input type="text" name='nombre' className="form"  {...register('nombre' ,{
              maxLength: 128,
              pattern: (/^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]*$/),
              required: true
            })}/>
            {errors.nombre?.type === 'maxLength' && <p className="error">Please type less than 128 characters</p>}
            {errors.nombre?.type === 'pattern' && <p className="error">Please type a real name</p>}
            {errors.nombre?.type === 'required' && <p className="error">Please type a name</p>}
            <label>Email</label>
            <input type="text" name='correo' className="form" {...register('correo' ,{
              maxLength: 512,
              pattern: (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
              required: true
            })}/>
            {errors.correo?.type === 'maxLength' && <p className="error">Please type less than 128 characters</p>}
            {errors.correo?.type === 'pattern' && <p className="error">Please type a real email</p>}
            {errors.correo?.type === 'required' && <p className="error">Please type an email</p>}
            <label>Phone number</label>
            <input type="text" name='telefono' className="form" {...register('telefono' ,{
              maxLength: 20,
              pattern: (/^(?=.*[0-9])[- +()0-9].+$/),
              required: true
            })}/>
            {errors.telefono?.type === 'maxLength' && <p className="error">Please type a valid phone number</p>}
            {errors.telefono?.type === 'pattern' && <p className="error">Please type a valid phone number</p>}
            {errors.telefono?.type === 'required' && <p className="error">Please type a phone number</p>}
            <label>Please tell us why you would be a good canidate</label>
            <textarea name='motivo' className="formularioRespuestaLarga" {...register('motivo' ,{
              maxLength: 300,
              pattern: (/^[a-zA-Z\s.,]*$/)
            })}/>
            {errors.motivo?.type === 'maxLength' && <p className="error">Please type less than 300 characters</p>}
            {errors.motivo?.type === 'pattern' && <p className="error">Use only letters, numbers, periods and commas</p>}
            <div className="buttonContainer">
              <input type="submit" value="Enviar" className="button" />
            </div>
          </form>
        </Container>
      }
      {formSent &&
        <EnCompletedForm />
      }
      <Footer />
      <LanguageButton pagina={'/es/form'} lenguaje={'Spanish'}/>
    </article>
  );
};

export default EnFormulario;
import React from 'react';
import './../style.css';
import icono from './../../resources/icono.png'
import toggle from './../../resources/toggle.png'
import {Nav, Navbar, Container} from 'react-bootstrap';
import LanguageButton from '../LanguageButton';

const EsHeader = () => { 
return ( 
  <Navbar expand="lg">
    <Container>
      <Navbar.Brand href="/">
        <img src={icono} alt='Ruta' className='logo'/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{borderColor: 'transparent'}}>
        <img src={toggle} alt='Norte' className='toggle'/>
      </Navbar.Toggle>
      <Navbar.Collapse>
        <Nav.Link href="/es/lodging">
          <p className='link'>Hospedaje</p>
        </Nav.Link>
        <Nav.Link href="/es/restaurants">
          <p className='link'>Alimentación</p>
        </Nav.Link>
        <Nav.Link href="/es/activities">
          <p className='link'>Actividades</p>
        </Nav.Link>
        <Nav.Link href="/es/volunteering">
          <p className='link'>Voluntariado</p>
        </Nav.Link>
      </Navbar.Collapse>
    </Container>
    <LanguageButton pagina={'/en/header'} lenguaje={'English'}/>
  </Navbar>
); 
}; 

export default EsHeader;

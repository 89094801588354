import React from 'react';
import { useParams } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import EmprendimientosJson from '../../resources/emprendimientos.json'
import EsHeader from "./EsHeader";
import Footer from '../Footer';
import LanguageButton from '../LanguageButton';


const EsBusiness = () => { 
  const {identificadorDelEmprendimiento} = useParams();
  const emprendimiento  = EmprendimientosJson.emprendimientos[identificadorDelEmprendimiento-1];
  return (
    <article>
      <EsHeader/>
      <br/><br/>
      <Container>
        <Row>
          <h1 className="title">{emprendimiento.titulo}</h1>
        </Row>
        <Row>
          <Col xl={5}>
            <img src={"/resources/" + emprendimiento.id + ".jpg"} alt='placeholder' className='business'/>
          </Col>
          <Col xl={7}>
              <article className='business'>
                <p className='business'>{emprendimiento.descripcion}</p>
              </article>
          </Col>
        </Row>
      </Container>
      <Footer/>
      <LanguageButton pagina={`/en/business/${identificadorDelEmprendimiento}`} lenguaje={'English'}/>
    </article>
  ); 
}; 

export default EsBusiness;
import React from "react";
import "./../style.css";
import { Col, Card } from "react-bootstrap";

export default class EnBusinessCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identificador: props.identificador,
      titulo: props.titulo,
      image: '../../../resources/' + props.identificador + '.png'
    }
  }
  render() {
    return (
      <Col lg={4}>
        <Card className="carta" style={{borderColor: 'transparent', borderRadius: '25px'}}>
          <a href={`/en/business/${this.state.identificador}`} className='cartaImageContainer'>
            <img src={this.state.image} alt='placeHolder' className='cartaImage'/>
          </a>
          <Card.Body className="cartaBody">
            <Card.Title>
              <h2 className="title">{this.state.titulo}</h2>
            </Card.Title>
          </Card.Body>
        </Card>
      </Col>
    );
  }
};

import React from "react";
import { Container, Row } from "react-bootstrap";
import Emprendimientos from '../../resources/emprendimientos.json'
import EsHeader from "./EsHeader";
import BusinessCard from "./EsBusinessCard";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const emprendimientos = Emprendimientos.actividades;
const EsActivities = () => {
  return (
    <article>
      <EsHeader />
      <br/><br/>
      <Container>
        <Row>
          <h3 className="subtitle">Nuestras diversas</h3>
        </Row>
        <Row>
          <h1 className="title">Actividades</h1>
        </Row>
        <Row>
          {emprendimientos.map((emprendimiento) => <BusinessCard titulo={emprendimiento.titulo} identificador={emprendimiento.id} key={emprendimiento.id}/>)}
        </Row>
      </Container>
      <Footer />
      <LanguageButton pagina={'/en/activities'} lenguaje={'English'}/>
    </article>
  );
};

export default EsActivities;

import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './style.css';
import logoBlanco from './../resources/logo.png'
import instagram from './../resources/instagram.png'
import sobre from './../resources/sobre.png'
import telefono from './../resources/telefono.png'
import arbol from './../resources/arbol.png'
import zacate from './../resources/zacate.png'

const Footer = () => { 
  return (
    <>
      <Container className='footerAfuera'>
        <Row>
          <Col xs={2} className='centrado'>
            <img src={arbol} alt='Sobre'/>
          </Col>
          <Col xs={2} className='centrado'>
            <img src={zacate} alt='Sobre'/>
          </Col>
          <Col xs={2} className='centrado'>
            <img src={arbol} alt='Sobre'/>
          </Col>
          <Col xs={2} className='centrado'>
            <img src={arbol} alt='Sobre'/>
          </Col>
          <Col xs={2} className='centrado'>
            <img src={zacate} alt='Sobre'/>
          </Col>
          <Col xs={2} className='centrado'>
            <img src={arbol} alt='Sobre'/>
          </Col>
        </Row>
      </Container>
      <footer>
        <Container >
          <Row>
            <Col className='centrado'>
              <p className='creditos'>Designed, developed and deployed by Andrés Prada.</p>
            </Col>
          </Row>
          <Row>
            <Col className='centrado'>
              <img src={logoBlanco} alt='Logo Blanco' className='footerImage'/>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className='centrado'>
              <p className='footerText'><img src={telefono} alt='Telefono' className='footerIcon'/> +506 8829 3334</p>
            </Col>
            <Col lg={4} className='centrado'>
              <p className='footerText'><img src={sobre} alt='Sobre' className='footerIcon'/> info@rutanortecostarica.com</p>
            </Col>
            <Col lg={4} className='centrado'>
              <p className='footerText'><img src={instagram} alt='Instagram' className='footerIcon'/> @rutanortecostarica</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  ); 
}; 

export default Footer;


import React from 'react';
import {Container} from 'react-bootstrap';

const EnCompletedForm = () => { 
  return (
    <article>
      <Container className='formularioHoja'>
        <form>
        <h1 className="title">The form has been completed</h1>
        <p className='parrafo'>
          Thank you for filling out our volunteer form.
          Your request will be sent to the entrepreneurs of Ruta Norte,
          From now on you are applying for our volunteer opportunities.
        </p>
        <div className='buttonContainer'><a href='/en' className='button'>Volver</a></div>
        </form>
      </Container>
      <div className='separador'></div>
    </article>
  ); 
}; 

export default EnCompletedForm;
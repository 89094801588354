import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import tapaDulce from "./../../resources/tapadulce.png";
import "./../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import EsHeader from "./EsHeader";
import Footer from "../Footer";
import LanguageButton from "../LanguageButton";

const EsVolunteering = () => {
  return (
    <article>
      <EsHeader />
      <br/><br/>
      <Container>
        <Row>
          <h3 className="subtitle">Nuestras oportunidades de</h3>
        </Row>
        <Row>
          <h1 className="title">Voluntariado</h1>
        </Row>
        <Row>
          <Col lg={6}>
            <img src={tapaDulce} alt="Tapa Dulce" className="voluntariadoImage" />
          </Col>
          <Col lg={6}>
            <p className="voluntariadoText">
            Al completar nuestro formulario, darás el primer paso para conectarte con un emprendedor de la Ruta Norte, 
            que te dará la oportunidad de explorar la Costa Rica desconocida como voluntario. 
            Así tendrás la oportunidad única de adquirir valiosas habilidades y experiencia mientras contribuyes a un proyecto 
            significativo que se alinea con tus intereses y valores.
            </p>
            <p className="voluntariadoTitle">
            ¡Te esperamos en la Ruta Norte!
            </p>
          </Col>
        </Row>
        <div className="buttonContainer">
          <a href="/es/form" className="button">
            Formulario
          </a>
        </div>
      </Container>
      <Footer />
      <LanguageButton pagina={'/en/volunteering'} lenguaje={'English'}/>
    </article>
  );
};

export default EsVolunteering;

import React from "react";
import logo from "./../../resources/logoNegro.png";
import icono from './../../resources/logoBlanco.png'
import toggle from "./../../resources/toggleBlanco.png";
import landingPageBackgroundVideo from "./../../resources/landingPageBackgroundVideo.webm";
import landingPageBackgroundImage from "./../../resources/landingPageBackgroundImage.JPG";
import triangulo from "./../../resources/triangulo.svg";
import { Container, Nav, Navbar } from "react-bootstrap";
import EsAboutUs from "./EsAboutUs";
import EsContactUs from "./EsContactUs";
import LanguageButton from "./../LanguageButton";
import Footer from "../Footer";

const EsMainPage = () => {
  return (
    <main>
      <section className="backgroundVideo">
        <video autoPlay loop muted poster={landingPageBackgroundImage}>
          <source src={landingPageBackgroundVideo} type="video/webm" />
        </video>
        <Navbar
          expand="lg"
          style={{
            backgroundColor: 'transparent',
            position: "fixed",
            width: "100%",
          }}
        >
          <Container>
            <Navbar.Brand href="/">
              <img src={icono} alt='Ruta' className='logo'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{borderColor: 'transparent'}}>
              <img src={toggle} alt='Norte' className='toggle'/>
            </Navbar.Toggle>
            <Navbar.Collapse>
              <Nav.Link href="/es/lodging">
                <p className='linkInicio'>Hospedaje</p>
              </Nav.Link>
              <Nav.Link href="/es/restaurants">
                <p className='linkInicio'>Alimentación</p>
              </Nav.Link>
              <Nav.Link href="/es/activities">
                <p className='linkInicio'>Actividades</p>
              </Nav.Link>
              <Nav.Link href="/es/volunteering">
                <p className='linkInicio'>Voluntariado</p>
              </Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <img src={logo} alt='Logo' className="logoInicio"/>
        <div className="buttonContainer">
          <div/>
          <a href="#scrollReference" className="buttonInicio">
            Conózcanos
          </a>
        </div>
      </section>
      <img src={triangulo} alt='Triangulo' className="trianguloInferior"/>
      <section className="scrollOver">
        <EsAboutUs />
        <EsContactUs />
        <Footer />
      </section>
      <LanguageButton pagina={'/en'} lenguaje={'English'}/>
    </main>
  );
};

export default EsMainPage;
